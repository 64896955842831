@import url(https://cdn.jsdelivr.net/npm/tailwindcss/dist/tailwind.min.css);
@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.206/distr/fira_code.css);

body {
  font-family: -apple-system, Roboto, "Open Sans", "Helvetica Neue", sans-serif;
}

.demo {
  font-family: "Fira Code", monospace;
}
